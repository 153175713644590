import "../CSS/PItem.css";

export default function PItem({ aLink, pTitle, pImg, pos, index, zPos, children }) {
    const currPos = (index === (pos - 1) ? 0 : index < pos ? -52 : pos === index ? 49 : index === (pos + 1) ? 99 : 149);
    const posStyle = {
        left: `${currPos - 12.5}%`,
        filter: `opacity(${(currPos >= 0 && currPos <= 100) ? 100 : 0}%)`,
        zIndex: zPos
    }
    return (
        <div className='pItemHitbox' style={posStyle}>
            <div className="pItemWrap">
                <div className="pItem">
                    <div className={"pImg " + pImg}>
                        <h5>{pTitle}</h5>
                    </div>
                    <div className="pContent flex">
                        <p className="pl1 pr1">{children}</p>
                        {aLink === "#" ? <></> : <a className="flb31 tac" href={aLink} target="_blank" rel="noreferrer">View</a>}
                    </div>
                </div>
            </div>
        </div>
    );
}