import React, { useState, useRef, useEffect } from 'react';
import "./CSS/colorSchemes.css";
import "./CSS/general.css"
import "./CSS/headerAndFooter.css";
import "./CSS/hero.css";
import "./CSS/App.css";
import Header from './comp/Header';
import Section from './comp/Section';
import Card from './comp/Card';
import PItem from './comp/PItem';
import NavBtn from './comp/NavBtn';
import emailjs from 'emailjs-com';
import portfolioItems from "./data/portfolioData.json";
import ComingSoon from './comp/ComingSoon';

export default function App() {
    // Constants
    const currentHour = new Date().getHours();
    const slides = ["Portfolio", "Contact", "Music"];
    const pArray = portfolioItems.pItems;

    // Refs
    const refNavBar = useRef(null);
    const refAbout = useRef(null);
    const refSkills = useRef(null);
    const refPortfolio = useRef(null);
    const refContact = useRef(null);
    const form = useRef(null);
    const refArr = [refAbout, refContact, refPortfolio];
    const refs = [refNavBar, refAbout, refSkills, refPortfolio, refContact];
    
    // State Variables
    const [csTop, setCSTop] = useState(0);
    const [colorScheme, setColorScheme] = useState(0);
    const [mode, toggleMode] = useState(currentHour >= 18 || currentHour <= 5 ? true : false);
    const [txt, setTxt] = useState("");
    const [slideIndex, setSlideIndex] = useState(0);
    const [h3Txt, setH3Txt] = useState(slides[slideIndex]);
    const textState = ["istyping", "isdeleting"];
    const [typing, setTyping] = useState(textState[0]);
    const [scrollBtn, setScrollBtn] = useState(false);
    const [h2One, setH2One] = useState(false);
    const [h2Two, setH2Two] = useState(false);
    const [sender_email, setSenderEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [pos, setPos] = useState(1);

    // useEffects
    useEffect(() => {
        const timeout = setTimeout(() => {
            setTxt(h3Txt.slice(0, txt.length + 1));
        }, 100);
        return () => clearTimeout(timeout);
    });
    useEffect(() => {
        const timeout = setTimeout(() => {
          if (typing === "istyping" && txt !== h3Txt) {        
            setTxt(h3Txt.slice(0, txt.length + 1));
          }
          else if ( (txt === h3Txt && typing==="isdeleting") || typing === "isdeleting" ) {
            setTxt(h3Txt.slice(0, txt.length - 1));
            if(txt.length<=1){
                setTyping(textState[0]);
                setH3Txt(slides[slideIndex]);
            }
          }
        }, 100);
        return () => clearTimeout(timeout);
    });

    // Event Listeners
    window.addEventListener("scroll", () => {
        if (window.scrollY > window.innerHeight) {
            setScrollBtn(true);
        }
        else {
            setScrollBtn(false);
        }
    });

    // Functions
    const changePos = (x) => {
        if (x === -1) {
            if (pos === 0) {
                setPos(pArray.length-1);
            }
            else setPos(pos-1);
        }
        else setPos((pos+1)%pArray.length);
    };
    const scrollToSection = (elementRef) => {
        window.scrollTo({
            top: elementRef.current?.offsetTop,
            behavior: "smooth"
        });
    }
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.init("OpSgDZ_LlyD5My5_O");
        emailjs.send("mccarty_cam_service","contact_form", {
            sender_email: sender_email,
            subject: subject,
            message: message,
        }
        ).then((response) => {
            setSenderEmail("");
            setSubject("");
            setMessage("");
            alert('SUCCESS!', response.status, response.text);
        }).catch((err) => {
            alert('FAILED...', err.text);
        });
    }
    

    // Handlers
    const handleSlideChange = (x) => {
        setTyping(textState[1]);
        if (x === 0) { setSlideIndex((slideIndex + 1) % 3) }
        else {
            if (slideIndex === 0) { setSlideIndex(2) }
            else { setSlideIndex(slideIndex - 1) }
        }
    }
    const handleCTA = () => {
        scrollToSection(refArr[slideIndex]);
        if (slideIndex===2) {
            setPos(0);
        }
    }

    ///////////////////////////////// Start of DOM
    if (window.innerWidth < 1500) {
        return (
            <ComingSoon />
        );
    }
    return (
        // The CSS modifiers on the .App element do the following: Toggle light/darkmode; Select color scheme;
        <div className={`App ${mode ? "d" : "l"}mode CS${colorScheme}`}
            >
            
            {/* Home */}
            <Header scrollToSection={scrollToSection} mode={mode} toggleMode={toggleMode} refNavBar={refs[0]} refAbout={refs[1]} refSkills={refs[2]} refPortfolio={refs[3]} refContact={refs[4]} />

            <div id="hero">
                <div className="hWrapper">
                    <div className='slideshow'>
                        <div className='hLClickableArea' onClick={()=>{handleSlideChange(1)}}><div className="lArrow" /></div>
                        <div className={`hImg ${slides[slideIndex]}`}><div className="hImgCover" /></div>
                        <div className='hRClickableArea' onClick={()=>{handleSlideChange(0)}}><div className="rArrow" /></div>
                    </div>
                    <h3 className='typedTitle'>{`>`} {txt}<span className={txt === h3Txt && typing === "istyping" ? 'cursor' : null}>|</span></h3>
                    <div className='ctaWrapper'><button className="ctaBtn" onClick={handleCTA}><div /></button></div>
                </div>
            </div>
            <main>
                {/* About */}
                <Section secRef={refAbout} color='lightGlass'>
                    <div className='pfp'>
                        <div className='pfpInner'></div>
                    </div>
                    <div className="hTitle">
                        <h2 className={`h2-1${h2One ? " h2-1Animated" : ""}`} onClick={()=>{setH2One(!h2One)}}>
                            <span>C</span>
                            <span>a</span>
                            <span>m</span>
                            <span>e</span>
                            <span>r</span>
                            <span>o</span>
                            <span>n</span>
                            <span className='space'></span>
                            <span>M</span>
                            <span>c</span>
                            <span>C</span>
                            <span>a</span>
                            <span>r</span>
                            <span>t</span>
                            <span>y</span>
                        </h2>
                        <h2 className={`h2-2${h2Two ? " h2-2Animated" : ""}`}>
                            <span onClick={()=>{setH2Two(!h2Two)}}>Web Developer</span>
                            <span onClick={()=>{setColorScheme(0)}}>Default Theme</span>
                            <span onClick={()=>{setColorScheme(1)}}>Flame</span>
                            <span onClick={()=>{setColorScheme(2)}}>Gumball</span>
                            <span onClick={()=>{setColorScheme(3)}}>Leafy</span>
                            <span onClick={()=>{setColorScheme(4)}}>Noir</span>
                            <span onClick={()=>{setColorScheme(5)}}>Party</span>
                        </h2>
                    </div>
                    <Card mods={"w60p h-centered mt1"}>
                        <h4>Welcome to my Portfolio!</h4>
                        <p>Hello, I'm Cameron, a web developer with a love for clean code and even cleaner graphic design. Whether it's crafting eye-catching webpages or diving into backend logic, I thrive on turning ideas into reality.</p>
                    </Card>
                </Section >
                {/* Skills and Interests */}
                <Section secRef={refSkills} color={"img3"}>
                    <div className='colorBkg-0' />
                    <div className='colorBkg-1' />
                    <div className='colorBkg-2' />
                    <h4 className='w80p'>Skills and Interests</h4>
                    <div className='cardViewer grid gtc3 gtr2 mt5'>
                        <Card mods={"b-light"}>
                            <h5>Frontend Development</h5>
                            <p>I specialize in building responsive, user-friendly interfaces using HTML, CSS, and JavaScript. From simple landing pages to complex web applications, I’ve got you covered.</p>
                        </Card>
                        <Card mods={"b-accent1"}>
                            <h5>Backend Development</h5>
                            <p>Behind every great frontend lies a robust backend. I work with NodeJS, Express, and databases (MongoDB, PostgreSQL) to create seamless experiences.</p>
                        </Card>
                        <Card mods={"b-accent2"}>
                            <h5>Full-Stack</h5>
                            <p>As a ReactJS and NodeJS developer, I bridge the gap between frontend and backend, ensuring a cohesive user experience.</p>
                        </Card>
                        <Card mods={"b-accent2"}>
                            <h5>Project Management</h5>
                            <p>I have developed my leadership and management skills during my academic career through specialized courses and group projects.</p>
                        </Card>
                        <Card mods={"b-light"}>
                            <h5>Support and Training</h5>
                            <p>What's a project if you don't know how to use it? In addition to building the project, I instruct the users on how to operate the finished product.</p>
                        </Card>
                        <Card mods={"b-accent1"}>
                            <h5>Music Production</h5>
                            <p>In my spare time, I enjoy writing electronic music. Check out <span className="inlineBtn" onClick={()=>{scrollToSection(refPortfolio);}}>my channel</span> below!</p>
                        </Card>
                    </div>
                </Section>
                {/* Portfolio */}
                <Section secRef={refPortfolio}>
                    <h4 className='hMin'>Portfolio</h4>
                    <div className={'pGridScroll left'} onClick={()=>{changePos(-1);}} />
                    <div className='portfolioGrid pl10 pr10 flex mt2'>
                        {pArray.map((item, i)=> {
                            return <PItem aLink={item.aLink} pTitle={item.pTitle} pImg={item.pImg} pos={pos} index={i} key={i} zPos={pArray.length - i}>{item.pContent}</PItem>;
                        })}
                    </div>
                    <div className='pGridScroll right' onClick={()=>{changePos(1);}} />
                </Section>
                {/* Contact */}
                <Section secRef={refContact} color={"img2"}>
                    <Card mods={"w60p pl5 pr5 h-centered mt1"}>
                        <h4>Contact</h4>
                        <form ref={form} className='flexWrapWrap mt3 mb2' onSubmit={sendEmail}>
                            <label className='mb2' htmlFor="sender_email"><span>Email:</span>
                                <input value={sender_email} name='sender_email' id="sender_email" type="email" onChange={(e)=>{setSenderEmail(e.target.value)}} required />
                            </label>
                            <label className='mb1' htmlFor="subject"><span>Subject:</span>
                                <input value={subject} name='subject' id="subject" type="text" onChange={(e)=>{setSubject(e.target.value)}} required />
                            </label>
                            <label className='mb2' htmlFor="message"><span>Message:</span>
                                <textarea value={message} name='message' id="message" onChange={(e)=>{setMessage(e.target.value)}} required />
                            </label>
                            <p className='h-centered flb100 tac'>I look forward to hearing from you!</p>
                            <button className='mt3' type="submit">Send</button>
                        </form>
                    </Card>
                    <div className='socialBar grid gtc3'>
                        <a className='socialBtn linkedIn' href="https://www.linkedin.com/in/cameron-m-31345728a/" target="_blank" rel="noreferrer" title='LinkedIn' />
                        <a className='socialBtn github' href="https://github.com/C-McCarty" target="_blank" rel="noreferrer" title='GitHub' />
                        <a className='socialBtn youTube' href="https://youtube.com/@emcemusic" target="_blank" rel="noreferrer" title='YouTube' />
                    </div>
                </Section>
            </main>
            <footer>
                <div className="addressWrapper">
                    <div className="navBtnHoverBar"></div>
                    <address>
                        Webmaster: Cameron McCarty<br />
                        Last Update: 01/18/2025<br />
                        All rights reserved.
                    </address>
                </div>
                <nav>
                    <NavBtn navType={1} scrollToSection={scrollToSection} currRef={refNavBar}>Home</NavBtn>
                    <NavBtn navType={1} scrollToSection={scrollToSection} currRef={refAbout}>About</NavBtn>
                    <NavBtn navType={1} scrollToSection={scrollToSection} currRef={refSkills}>Skills</NavBtn>
                    <NavBtn navType={1} scrollToSection={scrollToSection} currRef={refPortfolio}>Portfolio</NavBtn>
                    <NavBtn navType={1} scrollToSection={scrollToSection} currRef={refContact}>Contact</NavBtn>
                </nav>
            </footer>
            {/* Back to top button */}
            <div className={`bttBtnWrapper${scrollBtn ? "" : " bttHidden"}`}>
                <button className="bttBtn" onClick={()=>{scrollToSection(refNavBar)}}><div /></button>
            </div>
        </div>
    );
}